
import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

import { useAuth } from 'src/composables/auth';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [{
	name: 'main',
	path: '/',
	component: () => import('layouts/MainLayout.vue'),
	children: [
		//Dashboard routes


//applogs routes
		{
			path: '/applogs/:fieldName?/:fieldValue?',
			name: 'applogslist',
			component: () => import('pages/applogs/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/applogs/view/:id', 
			name: 'applogsview', 
			component: () => import('pages/applogs/view.vue'), 
			props: true 
		},
		{ 
			path: '/applogs/add', 
			name: 'applogsadd', 
			component: () => import('pages/applogs/add.vue'), 
			props: true 
		},
		{ 
			path: '/applogs/edit/:id', 
			name: 'applogsedit', 
			component: () => import('pages/applogs/edit.vue'), 
			props: true 
		},

//audi routes
		{
			path: '/audi/:fieldName?/:fieldValue?',
			name: 'audilist',
			component: () => import('pages/audi/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/audi/view/:id', 
			name: 'audiview', 
			component: () => import('pages/audi/view.vue'), 
			props: true 
		},

//empresas routes
		{
			path: '/empresas/:fieldName?/:fieldValue?',
			name: 'empresaslist',
			component: () => import('pages/empresas/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/empresas/view/:id', 
			name: 'empresasview', 
			component: () => import('pages/empresas/view.vue'), 
			props: true 
		},
		{ 
			path: '/empresas/add', 
			name: 'empresasadd', 
			component: () => import('pages/empresas/add.vue'), 
			props: true 
		},
		{ 
			path: '/empresas/edit/:id', 
			name: 'empresasedit', 
			component: () => import('pages/empresas/edit.vue'), 
			props: true 
		},

//empresausuarios routes
		{
			path: '/empresausuarios/:fieldName?/:fieldValue?',
			name: 'empresausuarioslist',
			component: () => import('pages/empresausuarios/list.vue'),
			props: route => passRouteToProps(route)
		},

//estadocaso routes
		{
			path: '/estadocaso/:fieldName?/:fieldValue?',
			name: 'estadocasolist',
			component: () => import('pages/estadocaso/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/estadocaso/view/:id', 
			name: 'estadocasoview', 
			component: () => import('pages/estadocaso/view.vue'), 
			props: true 
		},
		{ 
			path: '/estadocaso/add', 
			name: 'estadocasoadd', 
			component: () => import('pages/estadocaso/add.vue'), 
			props: true 
		},
		{ 
			path: '/estadocaso/edit/:id', 
			name: 'estadocasoedit', 
			component: () => import('pages/estadocaso/edit.vue'), 
			props: true 
		},

//genero routes
		{
			path: '/genero/:fieldName?/:fieldValue?',
			name: 'generolist',
			component: () => import('pages/genero/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/genero/view/:id', 
			name: 'generoview', 
			component: () => import('pages/genero/view.vue'), 
			props: true 
		},
		{ 
			path: '/genero/add', 
			name: 'generoadd', 
			component: () => import('pages/genero/add.vue'), 
			props: true 
		},
		{ 
			path: '/genero/edit/:id', 
			name: 'generoedit', 
			component: () => import('pages/genero/edit.vue'), 
			props: true 
		},

//idioma routes
		{
			path: '/idioma/:fieldName?/:fieldValue?',
			name: 'idiomalist',
			component: () => import('pages/idioma/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/idioma/view/:id', 
			name: 'idiomaview', 
			component: () => import('pages/idioma/view.vue'), 
			props: true 
		},
		{ 
			path: '/idioma/add', 
			name: 'idiomaadd', 
			component: () => import('pages/idioma/add.vue'), 
			props: true 
		},
		{ 
			path: '/idioma/edit/:id', 
			name: 'idiomaedit', 
			component: () => import('pages/idioma/edit.vue'), 
			props: true 
		},

//leadsmarketing routes
		{
			path: '/leadsmarketing/:fieldName?/:fieldValue?',
			name: 'leadsmarketinglist',
			component: () => import('pages/leadsmarketing/list.vue'),
			props: route => passRouteToProps(route)
		},

//leadsmarketingchar routes
		{
			path: '/leadsmarketingchar/:fieldName?/:fieldValue?',
			name: 'leadsmarketingcharlist',
			component: () => import('pages/leadsmarketingchar/list.vue'),
			props: route => passRouteToProps(route)
		},

//leadsmonth routes
		{
			path: '/leadsmonth/:fieldName?/:fieldValue?',
			name: 'leadsmonthlist',
			component: () => import('pages/leadsmonth/list.vue'),
			props: route => passRouteToProps(route)
		},

//leadspaymentmethod routes
		{
			path: '/leadspaymentmethod/:fieldName?/:fieldValue?',
			name: 'leadspaymentmethodlist',
			component: () => import('pages/leadspaymentmethod/list.vue'),
			props: route => passRouteToProps(route)
		},

//leadsphone routes
		{
			path: '/leadsphone/:fieldName?/:fieldValue?',
			name: 'leadsphonelist',
			component: () => import('pages/leadsphone/list.vue'),
			props: route => passRouteToProps(route)
		},

//leadsstatus routes
		{
			path: '/leadsstatus/:fieldName?/:fieldValue?',
			name: 'leadsstatuslist',
			component: () => import('pages/leadsstatus/list.vue'),
			props: route => passRouteToProps(route)
		},

//leadstypecase routes
		{
			path: '/leadstypecase/:fieldName?/:fieldValue?',
			name: 'leadstypecaselist',
			component: () => import('pages/leadstypecase/list.vue'),
			props: route => passRouteToProps(route)
		},

//learscustomer routes
		{
			path: '/learscustomer/:fieldName?/:fieldValue?',
			name: 'learscustomerlist',
			component: () => import('pages/learscustomer/list.vue'),
			props: route => passRouteToProps(route)
		},

//marketing routes
		{
			path: '/marketing/:fieldName?/:fieldValue?',
			name: 'marketinglist',
			component: () => import('pages/marketing/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/marketing/view/:id', 
			name: 'marketingview', 
			component: () => import('pages/marketing/view.vue'), 
			props: true 
		},
		{ 
			path: '/marketing/add', 
			name: 'marketingadd', 
			component: () => import('pages/marketing/add.vue'), 
			props: true 
		},
		{ 
			path: '/marketing/edit/:id', 
			name: 'marketingedit', 
			component: () => import('pages/marketing/edit.vue'), 
			props: true 
		},

//mediopago routes
		{
			path: '/mediopago/:fieldName?/:fieldValue?',
			name: 'mediopagolist',
			component: () => import('pages/mediopago/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/mediopago/view/:id', 
			name: 'mediopagoview', 
			component: () => import('pages/mediopago/view.vue'), 
			props: true 
		},
		{ 
			path: '/mediopago/add', 
			name: 'mediopagoadd', 
			component: () => import('pages/mediopago/add.vue'), 
			props: true 
		},
		{ 
			path: '/mediopago/edit/:id', 
			name: 'mediopagoedit', 
			component: () => import('pages/mediopago/edit.vue'), 
			props: true 
		},

//permissions routes
		{
			path: '/permissions/:fieldName?/:fieldValue?',
			name: 'permissionslist',
			component: () => import('pages/permissions/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/permissions/view/:id', 
			name: 'permissionsview', 
			component: () => import('pages/permissions/view.vue'), 
			props: true 
		},
		{ 
			path: '/permissions/add', 
			name: 'permissionsadd', 
			component: () => import('pages/permissions/add.vue'), 
			props: true 
		},
		{ 
			path: '/permissions/edit/:id', 
			name: 'permissionsedit', 
			component: () => import('pages/permissions/edit.vue'), 
			props: true 
		},

//petencialesnotas routes
		{
			path: '/petencialesnotas/:fieldName?/:fieldValue?',
			name: 'petencialesnotaslist',
			component: () => import('pages/petencialesnotas/list.vue'),
			props: route => passRouteToProps(route)
		},

//potencialesclientes routes
		{
			path: '/potencialesclientes/:fieldName?/:fieldValue?',
			name: 'potencialesclienteslist',
			component: () => import('pages/potencialesclientes/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/potencialesclientes/add', 
			name: 'potencialesclientesadd', 
			component: () => import('pages/potencialesclientes/add.vue'), 
			props: true 
		},
		{ 
			path: '/potencialesclientes/edit/:id', 
			name: 'potencialesclientesedit', 
			component: () => import('pages/potencialesclientes/edit.vue'), 
			props: true 
		},
		{ 
			path: '/potencialesclientes/scheduledday/:id', 
			name: 'potencialesclientesscheduledday', 
			component: () => import('pages/potencialesclientes/scheduledday.vue'), 
			props: true 
		},
		{ 
			path: '/potencialesclientes/note/:id', 
			name: 'potencialesclientesnote', 
			component: () => import('pages/potencialesclientes/note.vue'), 
			props: true 
		},
		{
			path: '/potencialesclientes/leardscustomer/:fieldName?/:fieldValue?',
			name: 'potencialesclientesleardscustomer',
			component: () => import('pages/potencialesclientes/leardscustomer.vue'),
			props: route => passRouteToProps(route)
		},
		{
			path: '/potencialesclientes/leadsprocess/:fieldName?/:fieldValue?',
			name: 'potencialesclientesleadsprocess',
			component: () => import('pages/potencialesclientes/leadsprocess.vue'),
			props: route => passRouteToProps(route)
		},
		{
			path: '/potencialesclientes/leadsinprocess/:fieldName?/:fieldValue?',
			name: 'potencialesclientesleadsinprocess',
			component: () => import('pages/potencialesclientes/leadsinprocess.vue'),
			props: route => passRouteToProps(route)
		},
		{
			path: '/potencialesclientes/leadscanceled/:fieldName?/:fieldValue?',
			name: 'potencialesclientesleadscanceled',
			component: () => import('pages/potencialesclientes/leadscanceled.vue'),
			props: route => passRouteToProps(route)
		},
		{
			path: '/potencialesclientes/leadssuspended/:fieldName?/:fieldValue?',
			name: 'potencialesclientesleadssuspended',
			component: () => import('pages/potencialesclientes/leadssuspended.vue'),
			props: route => passRouteToProps(route)
		},
		{
			path: '/potencialesclientes/leadsystem/:fieldName?/:fieldValue?',
			name: 'potencialesclientesleadsystem',
			component: () => import('pages/potencialesclientes/leadsystem.vue'),
			props: route => passRouteToProps(route)
		},
		{
			path: '/potencialesclientes/leadspending/:fieldName?/:fieldValue?',
			name: 'potencialesclientesleadspending',
			component: () => import('pages/potencialesclientes/leadspending.vue'),
			props: route => passRouteToProps(route)
		},
		{
			path: '/potencialesclientes/leadssignup/:fieldName?/:fieldValue?',
			name: 'potencialesclientesleadssignup',
			component: () => import('pages/potencialesclientes/leadssignup.vue'),
			props: route => passRouteToProps(route)
		},
		{
			path: '/potencialesclientes/leadschase/:fieldName?/:fieldValue?',
			name: 'potencialesclientesleadschase',
			component: () => import('pages/potencialesclientes/leadschase.vue'),
			props: route => passRouteToProps(route)
		},
		{
			path: '/potencialesclientes/leadsscheduled/:fieldName?/:fieldValue?',
			name: 'potencialesclientesleadsscheduled',
			component: () => import('pages/potencialesclientes/leadsscheduled.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/potencialesclientes/addd', 
			name: 'potencialesclientesaddd', 
			component: () => import('pages/potencialesclientes/addd.vue'), 
			props: true 
		},

//representantelegal routes
		{
			path: '/representantelegal/:fieldName?/:fieldValue?',
			name: 'representantelegallist',
			component: () => import('pages/representantelegal/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/representantelegal/view/:id', 
			name: 'representantelegalview', 
			component: () => import('pages/representantelegal/view.vue'), 
			props: true 
		},
		{ 
			path: '/representantelegal/add', 
			name: 'representantelegaladd', 
			component: () => import('pages/representantelegal/add.vue'), 
			props: true 
		},
		{ 
			path: '/representantelegal/edit/:id', 
			name: 'representantelegaledit', 
			component: () => import('pages/representantelegal/edit.vue'), 
			props: true 
		},

//roles routes
		{
			path: '/roles/:fieldName?/:fieldValue?',
			name: 'roleslist',
			component: () => import('pages/roles/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/roles/view/:id', 
			name: 'rolesview', 
			component: () => import('pages/roles/view.vue'), 
			props: true 
		},
		{ 
			path: '/roles/add', 
			name: 'rolesadd', 
			component: () => import('pages/roles/add.vue'), 
			props: true 
		},
		{ 
			path: '/roles/edit/:id', 
			name: 'rolesedit', 
			component: () => import('pages/roles/edit.vue'), 
			props: true 
		},

//tipocaso routes
		{
			path: '/tipocaso/:fieldName?/:fieldValue?',
			name: 'tipocasolist',
			component: () => import('pages/tipocaso/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tipocaso/view/:id', 
			name: 'tipocasoview', 
			component: () => import('pages/tipocaso/view.vue'), 
			props: true 
		},
		{ 
			path: '/tipocaso/add', 
			name: 'tipocasoadd', 
			component: () => import('pages/tipocaso/add.vue'), 
			props: true 
		},
		{ 
			path: '/tipocaso/edit/:id', 
			name: 'tipocasoedit', 
			component: () => import('pages/tipocaso/edit.vue'), 
			props: true 
		},

//usuarioempresa routes
		{
			path: '/usuarioempresa/:fieldName?/:fieldValue?',
			name: 'usuarioempresalist',
			component: () => import('pages/usuarioempresa/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/usuarioempresa/view/:id', 
			name: 'usuarioempresaview', 
			component: () => import('pages/usuarioempresa/view.vue'), 
			props: true 
		},
		{ 
			path: '/usuarioempresa/add', 
			name: 'usuarioempresaadd', 
			component: () => import('pages/usuarioempresa/add.vue'), 
			props: true 
		},
		{ 
			path: '/usuarioempresa/edit/:id', 
			name: 'usuarioempresaedit', 
			component: () => import('pages/usuarioempresa/edit.vue'), 
			props: true 
		},

//usuarios routes
		{
			path: '/usuarios/:fieldName?/:fieldValue?',
			name: 'usuarioslist',
			component: () => import('pages/usuarios/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/usuarios/view/:id', 
			name: 'usuariosview', 
			component: () => import('pages/usuarios/view.vue'), 
			props: true 
		},
		{ 
			path: '/account/edit', 
			name: 'usuariosaccountedit', 
			component: () => import('pages/account/accountedit.vue'), 
			props: true 
		},
		{ 
			path: '/usuarios/add', 
			name: 'usuariosadd', 
			component: () => import('pages/usuarios/add.vue'), 
			props: true 
		},
		{ 
			path: '/usuarios/edit/:id', 
			name: 'usuariosedit', 
			component: () => import('pages/usuarios/edit.vue'), 
			props: true 
		},
		{ 
			path: '/account', 
			name: 'usuariosaccountview', 
			component: () => import('pages/account/accountview.vue'), 
			props: true 
		},

//usuariosglobales routes
		{
			path: '/usuariosglobales/:fieldName?/:fieldValue?',
			name: 'usuariosglobaleslist',
			component: () => import('pages/usuariosglobales/list.vue'),
			props: route => passRouteToProps(route)
		},

		{ 
			path: '/query_contador', 
			name: 'query_contador', 
			component: () => import('pages/custom/query_contador.vue'), 
			props: true 
		},
		{ 
			path: '/reports', 
			name: 'reports', 
			component: () => import('pages/custom/reports.vue'), 
			props: true 
		},
		
		
//Password reset routes
		{ 
			path: '/index/forgotpassword', 
			name: 'forgotpassword', 
			component: () => import('pages/index/forgotpassword.vue') 
		},
		{ 
			path: '/index/resetpassword', 
			name: 'resetpassword', 
			component: () => import('pages/index/resetpassword.vue') 
		},
		{ 
			path: '/index/resetpassword_completed', 
			name: 'resetpassword_completed', 
			component: () => import('pages/index/resetpassword_completed.vue') 
		},
		
		

		//Error pages
		{ path:  '/error/forbidden', name: 'forbidden',  component: () => import('pages/errors/forbidden.vue') },
		{ path: '/error/notfound',  name: 'notfound', component: () => import('pages/errors/pagenotfound.vue') }
	],
	
	},
	{ path: '/:catchAll(.*)*', component: () => import('pages/errors/pagenotfound.vue') }
];

export default route(async function () {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE == 'history' ? createWebHistory : createWebHashHistory)
	
	const mainRoute = routes.find(x => x.name = "main");
	
	const auth = useAuth();

	
	const { user, userRole } = await auth.getUserData();
	if(user){
		const roleName = userRole.toLowerCase();
		
		let rolePage;
		switch(roleName){
			case "admin":
				rolePage = "admin";
				break;
			case "user":
				rolePage = "user";
				break;
			default:
				rolePage = "home";
		}
		
		const homeRoute = {
			path: '/', alias: '/home', 
			name: 'home', 
			component: () => import(`pages/home/${rolePage}.vue`) 
		}

		mainRoute.children.push(homeRoute);
	}
	else{
		const indexRoute = { 
			path: '/',  
			alias: '/home', 
			name: 'index', 
			component: () => import('pages/index/index.vue') 
		}
		mainRoute.children.push(indexRoute);
	}

	const Router = createRouter({
	scrollBehavior(to, from, savedPostion){
		if(savedPostion) return savedPostion;
		return { top:0 }
	},
    routes,
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })
  return Router
})

